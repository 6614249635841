import {
  ApolloCache,
  FetchResult,
  FieldMergeFunction,
  FieldPolicy,
} from '@apollo/client';

export type ItemInCollection = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __ref: string;
};

type ItemConnection = {
  items: ItemInCollection[];
  nextToken: string | null;
};

const merge: FieldMergeFunction<ItemConnection, ItemConnection> = (
  existing,
  incoming,
) => {
  return {
    ...(existing ?? []),
    ...incoming,
    items: [
      ...(existing?.items ?? []).filter(item =>
        incoming.items.every(i => i.__ref !== item.__ref),
      ),
      ...incoming.items,
    ],
  };
};

export const itemConnectionFieldPolicy: FieldPolicy<
  ItemConnection,
  ItemConnection
> = {
  keyArgs: ['__type'],
  merge,
};

export const modifyCacheOnDeleteItemInItemConnection = <
  TData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any>,
>(
  fieldName: string,
  deletedItemId: string | undefined,
  cache: TCache,
  result: Omit<FetchResult<TData>, 'context'>,
) => {
  if (!result.data || !deletedItemId) return;

  cache.modify({
    fields: {
      [fieldName]: (existing = {}) => {
        return {
          ...existing,
          items: existing.items.filter(
            (item: ItemInCollection) => !item.__ref.includes(deletedItemId),
          ),
        };
      },
    },
  });
};
