import 'tailwindcss/tailwind.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'katex/dist/katex.min.css';
import '@/global.css';
import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import Head, { HeadProvider } from '@common/frontend/components/Head';
import { useAuth } from '@common/frontend/hooks/use-auth';
import initialize from '@common/frontend-initializer';
import { itemConnectionFieldPolicy } from '@common/lib/in-memory-cache-config';

import type { AppProps } from 'next/app';

const { apolloClient } = initialize({
  sentryDsn:
    'https://5dea2af092bd42e59601d89c2b7ed0f4@o1174223.ingest.sentry.io/6270181',
  overrideConfig: (config, amplifyConfig) => ({
    ...amplifyConfig,
    Auth: {
      ...amplifyConfig.Auth,
      userPoolWebClientId: config.teacherUserPoolClientId,
      userPoolId: config.teacherUserPoolId,
    },
  }),
  inMemoryCacheConfig: {
    typePolicies: {
      Query: {
        fields: {
          listClassrooms: itemConnectionFieldPolicy,
          listTeachers: itemConnectionFieldPolicy,
        },
      },
    },
  },
});

const Component: React.FC<AppProps> = ({
  pageProps,
  Component: ChildComponent,
}) => {
  const { status } = useAuth();

  // 未認証状態に変化した場合はApolloのキャッシュをクリアする
  useEffect(() => {
    if (status === 'unauthenticated') {
      apolloClient.clearStore();
    }
  }, [status]);

  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <HeadProvider value={{ titlePrefix: '先生' }}>
          <Head />
          <ChildComponent {...pageProps} />
        </HeadProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
};

export default Component;
